<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { projects } from "@/config/api/projects";
/**
 * Project Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Projects",
      items: [
        {
          text: "All",
        },
        {
          text: "Projects",
          active: true,
        },
      ],
      ordersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "project_id", sortable: true, label: "Project Number" },
        { key: "name_en", sortable: true, label: "Name" },
        { key: "action", sortable: true },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    },
  },
  mounted() {
    // Set the initial number of items
    this.loadData();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    updatePage(page)
    {
          this.loadData(page);

    },
    updatePageLimit() {
      this.loadData(1, null, this.perPage);
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    loadData(page = 1, search = "", pageOptions = 10) {
      const api = {...projects.model.get};
      api.params = {is_approved: false, page: page, search: search, limit: pageOptions};
      this.generateAPI(api).then((res) => {
        this.ordersData = res.data.projects.docs;
        this.totalRows = res.data.projects.totalDocs;
      });
    },
    onRowClicked(item) {
      this.$router.push({
        path: "/project-info",
        query: { id: item.project_id },
      });
    },
    approve(item)
    {
      console.log(item)
      const api = projects.model.approve;
      api.id = item._id;
      this.generateAPI(api).then(() => {
        this.loadData();
      })
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-button
              id="addProject"
              @click="
                $router.push({
                  path: `add-projects`,
                })
              "
              pill
              variant="primary"
              >Add Project</b-button
            >

            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Projects</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                          @change="updatePageLimit"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="table-responsive">
                  <b-table
                    tbody-tr-class="rowClass"
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :busy="$store.state.api.loading"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="onRowClicked"
                    show-empty
                  >
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader
                          color="#505d69"
                          :loading="true"
                        ></pulse-loader>
                      </div>
                    </template>

                    <template v-slot:cell(paymentstatus)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Chargeback',
                          'badge-soft-success': `${row.value}` === 'Paid',
                          'badge-soft-warning': `${row.value}` === 'Unpaid',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(invoice)>
                      <button class="btn btn-light btn-rounded">
                        Invoice
                        <i class="mdi mdi-download ml-2"></i>
                      </button>
                    </template>
                    <template v-slot:cell(action)="row">
                        <b-dropdown>
                    <template v-slot:button-content>
                      Actions
                      <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item @click="approve(row.item)"
                      >Approve</b-dropdown-item
                    >
                  <b-dropdown-item >Reject</b-dropdown-item>
                </b-dropdown>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          @change="updatePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#addProject {
  float: right;
  margin-top: 10px;
}
</style>